<template>
  <div class="box-card">
    <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm">
      <el-row>
        <el-col :md="7">
          <el-form-item label="客户姓名:">
            <el-input v-model="searchForm.cust_name" placeholder="按姓名查询" clearable size="small" class="myInput"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="7">
          <el-form-item label="客户编号:">
            <el-input v-model="searchForm.cust_no" placeholder="请编号查询" clearable size="small" class="myInput"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="10">
          <el-form-item label="客户国家:">
            <el-select v-model="searchForm.cust_country" placeholder="按国家查询" size="small" clearable filterable>
              <el-option v-for="item in countryList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button class="vg_ml_16" type="primary" size="small" @click="searchBtn()">查询</el-button>
            <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
              ><i class="el-icon-refresh-right"></i> 刷新</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="vg_mb_16">
      <!-- <el-button type="warning" class="handleBtn" size="mini" @click="clearCust()">清空已添加</el-button> -->
      <el-button type="danger" class="handleBtn" size="mini" @click="cancelChoose()">取消选择</el-button>
      <!-- <el-button type="success" class="handleBtn" size="mini" @click="confirmCust()">确认添加</el-button> -->
    </el-row>

    <el-row>
      <el-table :data="tableData" class="checkTable" @cell-dblclick="confirmCust" v-loading="loadFlag" ref="myTable" border>
        <!-- <el-table-column type="selection" align="center"> -->
        <!-- </el-table-column> -->

        <!-- <el-table-column prop="cust_id" label="客户ID" align="center" width="100px">
                </el-table-column> -->
        <el-table-column prop="cust_no" label="客户编号" align="center"> </el-table-column>
        <el-table-column prop="cust_name" label="客户姓名" align="center"> </el-table-column>
        <el-table-column prop="cust_country" label="所属国家" align="center"> </el-table-column>
      </el-table>
    </el-row>

    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from '@api/request';
import pubPagination from '@/components/common/pubPagination';
import { custAPI } from '@api/modules/cust';
import { optnAPI } from '@api/modules/optn';

export default {
  name: 'custEditMiddleMan',
  components: {
    pubPagination
  },

  data() {
    return {
      searchForm: {
        cust_name: '',
        cust_no: '',
        cust_country: ''
      },
      tableData: [],
      totalPage: 0,
      currentPage: 1,
      countryList: [],
      cust_pid_name: null,
      cust_id: null,
      loadFlag: true
    };
  },
  created() {
    this.getMiddleCust();
    this.getCountry();
  },
  methods: {
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMiddleCust();
    },
    // 查找按钮
    searchBtn() {
      this.loadFlag = true;
      this.currentPage = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.changePageSearch(1);
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        cust_name: '',
        cust_no: '',
        cust_country: ''
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getMiddleCust();
    },
    // 获取客户列表
    getMiddleCust() {
      get(custAPI.getMiddleCusts, {
        custName: this.searchForm.cust_name,
        custNo: this.searchForm.cust_no,
        custCountry: this.searchForm.cust_country,
        pageNo: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.totalItem;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取国家
    getCountry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10003 })
        .then(res => {
          if (res.data.code === 0) {
            this.countryList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 表单多选方法
    // handleSelectionChange(val) {
    //     this.cust_name_list = val.map(item => item.cust_name)
    //     this.multipleSelection = val.map(item => item.cust_id)
    // },
    // 表单单选方法
    selectRow(val, row) {
      this.$refs.myTable.clearSelection();
      this.$refs.myTable.toggleRowSelection(row, true);
      this.cust_id = row.cust_id;
      this.cust_pid_name = row.cust_name;
    },
    // 取消选择按钮
    cancelChoose() {
      this.$refs.myTable.clearSelection();
      this.$emit('cancelCust');
    },
    // 确认添加客户
    confirmCust(row) {
      // if (this.cust_id == null || this.cust_pid_name == null ) {
      //     this.$message({ message: '请选择客户！', type: 'error' });
      //     return;
      // }
      console.log('row', row);
      this.$emit('custPID', row.cust_id);
      this.$emit('custName', row.cust_name);
      this.close();
    },
    // 清空选择
    clearCust() {
      this.$emit('custPID', null);
      this.$emit('custName', null);
      this.close();
    },
    // 关闭窗口
    close() {
      this.$emit('custCancel');
    }
  }
};
</script>
<style>
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}

/* 搜索条件前缀 */
.inputPrefix {
  padding-top: 2px;
  font-size: 15px;
  width: 80px;
  margin-left: 20px;
}

/* 按钮 */
.handleBtn {
  margin-right: 20px;
  margin-top: 20px;
}
/* .checkTable > .el-table__header-wrapper > table > thead > tr > th:first-child > div {
	display: none!important;
} */
</style>

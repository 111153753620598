<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="getCustsNow()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="机构类别:" prop="inst_type">
              <el-select size="small" filterable v-model="searchForm.inst_type" placeholder="请选择机构类型">
                <el-option v-for="item in instTypes" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="机构编号:" prop="inst_no">
              <el-input size="small" v-model.trim="searchForm.inst_no" clearable placeholder="请填写机构编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="机构简称:" prop="inst_abbr">
              <el-input v-model.trim="searchForm.inst_abbr" placeholder="请填写机构简称" clearable size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8">
            <el-form-item label="中文名称:" prop="inst_cname">
              <el-input size="small" v-model.trim="searchForm.inst_cname" clearable placeholder="请填写中文名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="16">
            <el-form-item label="英文名称:" prop="inst_ename">
              <el-input v-model.trim="searchForm.inst_ename" placeholder="请填写英文名称" clearable size="small"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getCustsNow()" class="vg_ml_16">查询 </el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                ><i class="el-icon-refresh-right"></i> 刷新</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="vd_button_group vg_mtb_16"></div>
    <el-row>
      <el-col :md="24">
        <el-table class="vg_cursor" ref="multiTable" :data="tableData" @row-dblclick="dbClickJp" border v-loading="loadFlag">
          <el-table-column label="序号" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="机构类别" prop="inst_type"></el-table-column>
          <el-table-column label="机构编号" prop="inst_no" show-overflow-tooltip></el-table-column>
          <el-table-column label="机构简称" prop="inst_abbr"></el-table-column>
          <el-table-column label="机构中文" prop="inst_cname"></el-table-column>
          <el-table-column label="机构英文" prop="inst_ename">
            <template slot-scope="scope">
              <span v-if="scope.row.inst_ename">{{ scope.row.inst_ename }}</span>
              <span v-else>暂无</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { instAPI } from '@api/modules/inst';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import axios from 'axios';
export default {
  name: 'instList',
  components: {
    pubPagination
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        inst_type: null, //机构类型
        inst_no: null, //机构编号
        inst_abbr: null, //机构简称
        inst_cname: null, //中文名称
        inst_ename: null, //英文名称
        stff_id: null,
        status: null,
        timeValue: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      instTypes: [],
      statusList: [
        {
          id: 0,
          label: '草拟'
        },
        {
          id: 1,
          label: '在批'
        },
        {
          id: 2,
          label: '生效'
        }
      ]
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/inst_add' || from.path === '/inst_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getInstsList();
      this.getInstAttrList();
    },
    getInstsList() {
      get(instAPI.getAllInstsV1, {
        inst_type: this.searchForm.inst_type,
        inst_no: this.searchForm.inst_no,
        inst_abbr: this.searchForm.inst_abbr,
        inst_cname: this.searchForm.inst_cname,
        inst_ename: this.searchForm.inst_ename,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 获取机构类别
    getInstAttrList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10012 })
        .then(res => {
          if (res.data.code === 0) {
            this.instTypes = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    // 刷新
    buttonRefresh() {
      this.$refs.searchForm.resetFields();
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getCustsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getInstsList();
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      console.log('row', row);
      this.$emit('instAbbrChoose', row);
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getInstsList();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
